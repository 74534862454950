import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookingpage',
  templateUrl: './bookingpage.component.html',
  styleUrls: ['./bookingpage.component.css']
})
export class BookingpageComponent implements OnInit {
  bookingIframe: any;

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.hideIframeHeaderFooter();
  }

  hideIframeHeaderFooter(): void {
    const iframe = this.bookingIframe.nativeElement as HTMLIFrameElement;
    
    iframe.onload = () => {
      try {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDocument) {
          // Hide header and footer if they are present
          const header = iframeDocument.querySelector('header');
          const footer = iframeDocument.querySelector('footer');
          
          if (header) header.style.display = 'none';
          if (footer) footer.style.display = 'none';
        }
      } catch (error) {
        console.error('Error accessing iframe content:', error);
      }
    };
  }
}
