<div class="about-section">
    <div class="row">
      <div class="col-md-6">
        <div class="small-section">
          <div class="wrapper">
            <div class="grid-container">
              <div class="item1">
                <!-- <img src="assets/images/1.jpeg" alt="overview"> -->
                <img *ngIf="property?.imageList[0]?.url" src="{{property?.imageList[0]?.url}}" alt="overview">
                <img *ngIf="!property?.imageList[0]?.url" src="assets/images/1.jpeg" alt="overview">
              </div>
              <div class="item2">
                <!-- <img src="assets/images/2.jpeg" alt="overview"> -->
                <img *ngIf="property?.imageList[1]?.url" src="{{property?.imageList[1]?.url}}" alt="overview">
                <img *ngIf="!property?.imageList[1]?.url" src="assets/images/2.jpeg" alt="overview">
              </div>
              <div class="item3">
                <!-- <img src="assets/images/3.jpeg" alt="overview"> -->
                <img *ngIf="property?.imageList[2]?.url" src="{{property?.imageList[2]?.url}}" alt="overview">
                <img *ngIf="!property?.imageList[2]?.url" src="assets/images/3.jpeg" alt="overview">
              </div>
              <div class="item4">
                <!-- <img src="assets/images/4.jpeg" alt="overview"> -->
                <img *ngIf="property?.imageList[3]?.url" src="{{property?.imageList[3]?.url}}" alt="overview">
                <img *ngIf="!property?.imageList[3]?.url" src="assets/images/4.jpeg" alt="overview">
              </div>
              <div class="item5">
                <!-- <img src="assets/images/5.jpeg" alt="overview"> -->
                <img *ngIf="property?.imageList[4]?.url" src="{{property?.imageList[4]?.url}}" alt="overview">
                <img *ngIf="!property?.imageList[4]?.url" src="assets/images/5.jpeg" alt="overview">
              </div>
            </div>
          </div>

          <div class="wrapper-1">
            <div class="grid-container-1" [style.transform]="'translateX(' + (-currentIndex * 100) + '%)'">
              <div class="slide" *ngFor="let item of slideItems; let i = index">
                <img [src]="item.src" [alt]="item.alt">
              </div>
            </div>
            <button class="prev" (click)="prevSlide()">‹</button>
            <button class="next" (click)="nextSlide()">›</button>
          </div>
                    
        </div>
      </div>
      <div class="col-md-6" id="amenities">
        <div class="large-section">
          <div id="service" class="features-section">
            <div class="features-heading">
              <div class="star-ratings">★★★★★</div>
              <h2>Discover <span>Luxury</span><br><span> and Comfort</span> at its finest</h2>
            </div>
            <div class="features-grid">
              <div class="feature-item" *ngFor="let item of property?.propertyServicesList.slice(0, 6)">
                <div class="feature-content">
                  <img *ngIf="item?.imageUrl" src="{{item.imageUrl}}" alt="{{item.name}}">
                  <img *ngIf="!item?.imageUrl" src="assets/images/service.png" alt="{{item.name}}">
                  <p>{{item.name}}</p>
                </div>
              </div>
            </div>
            <div class="availability-check">
              <a href="#" class="check-btn" (click)="bookYourStay()">Check Availability  →</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  