import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-near-places',
  templateUrl: './near-places.component.html',
  styleUrls: ['./near-places.component.scss']
})
export class NearPlacesComponent implements OnInit {
data = [
{
  name:"subha",
  url:"assets/images/imge1.png"
},
{
  name:"subha",
  url:"assets/images/img2.png"
},
{
  name:"subha",
  url:"assets/images/img3.png"
},
{
  name:"subha",
  url:"assets/images/img4.png"
}
]
dataone = [
  {
    name:"subha",
    url:"assets/images/aboutimage3.png"
  },
  {
    name:"subha",
    url:"assets/images/aboutimage3.png"
  },
  {
    name:"subha",
    url:"assets/images/aboutimage3.png"
  }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  slideConfig = {
    centerMode: true,
    centerPadding: '20%',
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
    ],
  };

}
