import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  property: any;
  mobileActive: boolean = false;
  sticky: boolean = false;
  businessUser: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    public token: TokenStorage,
    public apiService: ApiService
  ) {
    this.property = new Property();
  }

  ngOnInit(): void {
    if (this.token.getProperty() !== null) {
      this.property = this.token.getProperty();
    } else {
      this.getProperty();
    }
    this.changeTheme( this.property.primaryColor,
      this.property.secondaryColor,
      this.property.tertiaryColor)
  }




  changeTheme(primary: string, secondary: string, tertiary: string) {
    document.documentElement.style.setProperty('--primary', primary);

    document.documentElement.style.setProperty('--secondary', secondary);
    document.documentElement.style.setProperty('--tertiary', tertiary);
    document.documentElement.style.setProperty('--button-primary', tertiary);
    document.documentElement.style.setProperty(
      '--primary-gradient',
      'linear-gradient( 180deg, ' + tertiary + ', ' + secondary + ')'
    );
    document.documentElement.style.setProperty(
      '--secondary-gradient',
      'linear-gradient( 312deg, ' + primary + ', ' + secondary + ')'
    );
    document.documentElement.style.setProperty(
      '--secondary-one-gradient',
      'linear-gradient( 180deg, ' + primary + ', ' + secondary + ')'
    );

    document.documentElement.style.setProperty(
      '--third-gradient',
      'linear-gradient( 180deg, ' + primary + ', ' + secondary + ')'
    );
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.sticky = verticalOffset > 50;
  }

  menuToggle() {
    this.mobileActive = !this.mobileActive;
  }

  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(
      (response) => {
        this.property = response.body;
        this.changeTheme( this.property.primaryColor,
          this.property.secondaryColor,
          this.property.tertiaryColor)
        this.token.saveProperty(this.property);
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          // Handle error appropriately
        }
      }
    );
  }

  bookYourStay() {
    window.open(
      "https://bookonepms.com/" + this.token.getProperty().seoFriendlyName + "?BookingEngine=true",
      '_blank'
    );
  }

  scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      this.mobileActive = false; // Close the mobile menu after clicking
    }
  }
}
