import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { DateModel } from 'src/app/model/dateModel';
import { Room } from 'src/app/model/room';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class RoomsComponent implements OnInit, AfterViewInit {
  rooms: Room[] = [];
  chunkedRooms: Room[][] = [];
  dateModel: DateModel = new DateModel();

  @ViewChild('carousel', { static: false }) carousel: ElementRef | undefined;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private apiService: ApiService,
    private tokenStorage: TokenStorage
  ) {}

  ngOnInit(): void {
    this.loadRooms();
  }

  ngAfterViewInit(): void {
    this.initializeCarousel();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateChunks();
  }

  private loadRooms(): void {
    this.apiService.getRoomDetailsByPropertyId(PROPERTY_ID).subscribe({
      next: (response) => {
        this.rooms = response.body || [];
        this.updateChunks();
        console.log("Loaded room data:", this.rooms);
      },
      error: (error: HttpErrorResponse) => {
        console.error("Error loading rooms:", error);
      }
    });
  }

  private updateChunks(): void {
    const chunkSize = this.getChunkSize();
    this.chunkedRooms = this.chunkArray(this.rooms, chunkSize);
  }

  private getChunkSize(): number {
    const width = window.innerWidth;
    if (width < 768) return 1;
    if (width < 992) return 2;
    return 3;
  }

  private chunkArray(array: Room[], chunkSize: number): Room[][] {
    const result: Room[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  scrollToSection(section: string): void {
    this.router.navigate([], { fragment: section }).then(() => {
      this.viewportScroller.scrollToAnchor(section);
    });
  }

  bookYourStay(): void {
    const property = this.tokenStorage.getProperty();
    const url = `https://bookonepms.com/${property.seoFriendlyName}?BookingEngine=true`;
    window.open(url, '_blank');
  }

  toggleFacilities(room: Room): void {
    room.showMore = !room.showMore;
  }

  private initializeCarousel(): void {
    if (this.carousel && this.carousel.nativeElement) {
      const carouselElement = this.carousel.nativeElement;
      // Initialize Bootstrap carousel if necessary (usually not needed with correct script inclusion)
      // new (window as any).bootstrap.Carousel(carouselElement);
    }
  }
}
