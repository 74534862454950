import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'src/app/token.storage';


@Component({
  selector: 'app-notch',
  templateUrl: './notch.component.html',
  styleUrls: ['./notch.component.css'],
})
export class NotchComponent implements OnInit {



  slideConfig =
  {
    centerMode: true,
    centerPadding: '34%',
    slidesToShow: 1,
    dots: true,
    arrows: false,
    responsive: [
     {
       breakpoint: 1025,
       settings: {
         centerPadding: '10px',
         slidesToShow: 2,
       }
     },
     {
       breakpoint: 767,
       settings: {
         centerPadding: '10px',
         slidesToShow: 1
       }
     }
    ]
  };

  title: string = 'Reviews';
  desc: string = 'Hers’s what our guests have to say ';
  data: any = [
    {
      message:
        'Amazing place!!! A home away from home ....excellent staff...scrumptious food and very aesthetically designed surroundings. You do not feel like a guest at all...ur part of their family. Visit Hotel Shree Sai Wada to experience the positive vibes of the place ❤️',
      name: 'Feeling recharged.....',
      position: 'Gauri Durve',
      image: 'assets/images/test_img1.jpg',
    },
    {
      message:
        "#positivereview #longpost #homeawayfromhome. Life is all about home and people you love or who love you and trust me this place makes you feel loved,welcomed and appreciated every moment.  From the entry to this place there is wide smiles, hospitality and love. Each and every member from owners to staff are truly dedicated to you. Their ultimate motive is to give you utmost care and make you feel special.  The food is an icing on the cake. Each item that we ordered was awesome and we'll prepared with all the pampering around. A place which has a small temple, lounge, swimm pool and utmost cozy rooms will make you feel at peace. If you really need relaxation do visit this place. It will make you forget your worries and you can relax to the utmost level.  Will visit for another stay soon. Keep up the great work team Hotel Shree Sai Wada. ...",
      name: 'Home away from home destination',
      position: 'Shibani Thomas Desai',
      image: 'assets/images/test_img1.jpg',
    },
    {
      message:
        'Awesome and a clean place!! Excellent service with utmost care . Food is amazing, staff takes good care in the best way possible and are always ready to take care of all your requests. Great place with family, friends and very apt for celebrations. Comfortable rooms , bathrooms and a lovely pool . Would highly recommend Hotel Shree Sai Wada to everyone!! Very well located away from the crowds.',
      name: 'Home away from home.',
      position: 'Malti Wadhwa',
      image: 'assets/images/test_img1.jpg',
    },
    {
      message:
        "It was a pleasant stay. Liked the ambiance and the staff are excellent. They have cooperated a lot with us. The food and room service was very well satisfactory. The best part is it's location in the Shirdi, Maharashtra with easy road access without traffic and noise",
      name: 'Nice place stay with family',
      position: 'Rahul',
      image: 'assets/images/test_img1.jpg',
    },
  ];

  bookYourStay(){
    window.open("https://bookonepms.com/" + this.token.getProperty().seoFriendlyName + "?BookingEngine=true", '_blank');
  }
  constructor(
    public token: TokenStorage
  ) {}

  ngOnInit(): void {}
  
}
