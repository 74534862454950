<div id="room" class="hotel_rooms_area ptb-100-5">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="section_title align-center">
          <div class="rooms-container">
            <div class="room-header">
              <h2 style="color: #fff;">ROOMS & SUITES</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li *ngFor="let chunk of chunkedRooms; let i = index"
            [class.active]="i === 0"
            [attr.data-bs-slide-to]="i"></li>
      </ol>

      <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let chunk of chunkedRooms; let i = index" [ngClass]="{'active': i === 0}">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4" *ngFor="let room of chunk">
              <div class="room-card mb-4">
                <span class="room-name">{{room.name}}</span>
                <img *ngIf="room.imageList?.length" [src]="room.imageList[0].url" [alt]="room.name" class="room-image">
                <span class="room-price">₹{{room.roomOnlyPrice}}/ Night</span>

                <!-- Facilities Container -->
                <div class="facilities-container">
                  <ul class="facilities-list" *ngIf="!room.showMore">
                    <li *ngFor="let item of room.roomFacilities | slice:0:3">{{item.name}}</li>
                  </ul>
                  <ul class="facilities-list" *ngIf="room.showMore">
                    <li *ngFor="let item of room.roomFacilities">{{item.name}}</li>
                  </ul>
                </div>

                <!-- Buttons Container for Read More and Book Now -->
                <div class="buttons-container" >
                  <button *ngIf="room.roomFacilities.length > 2" (click)="toggleFacilities(room)" class="btn btn-primary small-btn" style="margin-right: 10px;">
                    {{ room.showMore ? 'Show Less' : 'Read More' }}
                  </button>
                  <button (click)="bookYourStay()" class="btn btn-primary small-btn">Book Now</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Carousel Controls -->
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>
