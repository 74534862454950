<div class="navbar_inner_area">
  <nav class="navigation-area overlay sticky-navbar fadeInUp" [ngClass]="{'sticky': sticky}" data-sticky-offset="50">
    <div class="menu_toggle" (click)="menuToggle()">
      <i class="fa fa-align-left"></i>
    </div>
    <div class="navbar-mobile" [ngClass]="{'active': mobileActive}" style="display: flex; justify-content: center;">
      <ul class="navbar-nav">
        <li><a routerLink="/" class="brand-logo">
          <img src="{{token?.getProperty()?.logoUrl}}" class="logo" alt="Brand Logo">
        </a></li>
        <li class="menu"><a (click)="scrollToSection('home')">Home</a></li>
        <li class="menu"><a (click)="scrollToSection('room')">Rooms</a></li>
        <li class="menu"><a (click)="scrollToSection('testimonials')">Testimonials</a></li>
        <li class="menu"><a (click)="scrollToSection('amenities')">Amenities</a></li>
        <li class="menu"><a (click)="scrollToSection('attractions')">Nearby Attractions</a></li>
        <li class="menu"><a (click)="scrollToSection('location')">Location</a></li>
        <li>
          <a href="tel:+{{token.getProperty().mobile}}">
            <button class="book-button call-us">
              <i class="fa fa-phone"></i> Call Us
            </button>
          </a>
        </li>
        <li>
          <button class="book-button" (click)="bookYourStay()">
            <span class="green-dot pulse"></span>
            Book Your Stay
          </button>
        </li>
      </ul>
    </div>
  </nav>
</div>
