import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  NgbCalendar,
  NgbCarouselConfig,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerNavigateEvent,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { PROPERTY_ID } from 'src/app/app.component';
import { Booking } from 'src/app/model/booking';
import { DateModel } from 'src/app/model/dateModel';
import { Property } from 'src/app/model/property';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers: [
    NgbCarouselConfig,

  ], // add NgbCarouselConfig to the component providers
})
export class SliderComponent implements OnInit {
  property: Property;
  currentDay: string;
  day: string;
  year: string;
  month: number;


  searchString: string;
  cities: any[];



  firstName:string='';
  lastName:string='';
  email:string = '';
  phone:string = '';
  searchFilteredCities: any[];
  searchResultCity: string;
  isAddressFound: boolean = true;
  isAddressSearch: boolean = false;
  isLoginFrom: boolean = false;
  isStreetSelect: boolean = false;
  isSearchItem: boolean = false;
  todayPrice:any[];
  hoveredDate: NgbDate | null = null;
  propertyUrl: string = 'Deluxe Room';
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  roomsAndOccupancy: boolean = false;
  todayDate: NgbDate | null;
  adults: number = 1;
  propertyID:number =1989;
  children3to5: number = 0;
  children: number = 0;
  rooms: number = 1;
  booking: Booking;
  heroIndex = 1;
// datePriceMapping: { date: string; price: number }[] = datePriceMapping;
  bannerImage = "assets/images/ckbr-swimming-pool-16.jpg";
  country: string;
  resdata: { date: string; roomPrice: number }[]
  roomPrice: any;
  selectedMonth: number;
  day2: string;
  year2: string;
  month2: number;
  isExpanded = false;



  dateModel: DateModel;

  minToDate: Date;
  maxToDate: Date;

  minFromDate: Date;
  maxFromDate: Date;
  toDateMinMilliSeconds: number;
  toDateMaxMilliSeconds: number;
  fromDateMinMilliSeconds: number;
  fromDateMaxMilliSeconds: number;

  placement = 'top';

  checkIn: NgbDate;
  checkOut: NgbDate;
  guest: number = 1;
  noOfRooms: number = 1;
  noOfChildren: number = 0;

  CheckIn: FormControl = new FormControl();
  CheckOut: FormControl = new FormControl();
  Guest: FormControl = new FormControl();
  NoOfRooms: FormControl = new FormControl();
  NoOfChildren: FormControl = new FormControl();

  form = new FormGroup({
    CheckIn: new FormControl(),
    CheckOut: new FormControl(),
    Guest: new FormControl(),
    NoOfRooms: new FormControl(),
    NoOfChildren: new FormControl(),
  });

  slideImage = [
    {
      imageUrl: 'assets/images/royal9.png',
      title: '',
    },
    {
      imageUrl: 'assets/images/00000011.jpg',
      title: '',
    },
    {
      imageUrl: 'assets/images/royal13.jpg',
      title: '',
    },
    // {
    //   imageUrl: 'assets/images/dgreenvillage1.png',
    //   title: '',
    // },
  ];
  slideConfig = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          centerPadding: '0',
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
    ],
  };
  maxOccupancy: number;
  maxSelectedRooms: number;
  hotelID: any;
  checkinDay: any;
  checkinMonth: any;
  checkinYear: any;
  nights: any;
  backgroundImageUrl: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    public token: TokenStorage,
    public apiService: ApiService,
    public config: NgbCarouselConfig,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private acRoute: ActivatedRoute,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    config.interval = 5000;
    config.keyboard = true;
    this.booking = new Booking()
    config.pauseOnHover = true;
    this.backgroundImageUrl = 'src/assets/images/hero-section.jpg';
  }

  ngOnInit(): void {
    this.dateModel = new DateModel();
    this.getProperty();
    this.todayDate = this.calendar.getToday();

    // Initialize fromDate and toDate if they are undefined
    this.fromDate = this.fromDate || this.calendar.getToday();
    this.toDate = this.toDate || this.calendar.getNext(this.calendar.getToday(), 'd', 1);
console.log(" this.fromDate = this.fromDate" + JSON.stringify(this.fromDate))
console.log(" this.toDate"+ JSON.stringify(this.toDate))
  }


  toggleContent(): void {
    this.isExpanded = !this.isExpanded;
  }
  mileSecondToNGBDate(date: string) {
    const dsd = new Date(date);
    const year = dsd.getFullYear();
    const day = dsd.getDate();
    const month = dsd.getMonth() + 1;
    return { year: year, month: month, day: day };
  }

  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(
      (response) => {
        this.property = response.body;
        this.backgroundImageUrl = this.property.imageList[0].url;
        this.maxOccupancy = this.property.maximumOccupancy;
        this.maxSelectedRooms = this.property.numberOfRooms;
        this.token.saveProperty(this.property);
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
        }
      }
    );
  }
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  submitForm(searchForm) {}
  // validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
  //   const parsed = this.formatter.parse(input);
  //   return parsed && this.calendar.isValid(NgbDate.from(parsed))
  //     ? NgbDate.from(parsed)
  //     : currentValue;
  // }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {

    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  model(checkIn) {
    console.log('model: ', checkIn);
  }
  onBook() {
    if (this.checkIn === null) {
      this.dateModel.checkIn =
        this.year + '-' + (this.month + 1) + '-' + this.day;
    } else {
      // this.dateModel.checkIn = this.getDateFormat(this.checkIn);
      this.dateModel.checkIn = this.getDateFormatYearMonthDay(
        this.checkIn.day,
        this.checkIn.month,
        this.checkIn.year
      );
    }

    if (this.checkOut === null) {
      this.dateModel.checkOut =
        this.year2 + '-' + (this.month2 + 1) + '-' + this.day2;
    } else {
      // this.dateModel.checkOut = this.getDateFormat(this.checkOut);
      this.dateModel.checkOut = this.getDateFormatYearMonthDay(
        this.checkOut.day,
        this.checkOut.month,
        this.checkOut.year
      );
    }
    if (this.guest === null) {
      this.dateModel.guest = 1;
    } else {
      this.dateModel.guest = this.guest;
    }
    if (this.noOfChildren === null) {
      this.dateModel.noOfChildren = 1;
    } else {
      this.dateModel.noOfChildren = this.noOfChildren;
    }
    if (this.noOfRooms === null) {
      this.dateModel.noOfRooms = 1;
    } else {
      this.dateModel.noOfRooms = this.noOfRooms;
    }
    // console.log(' this.dateModel ' + JSON.stringify(this.dateModel));

    const navigationExtras: NavigationExtras = {
      queryParams: {
        dateob: JSON.stringify(this.dateModel),
      },
    };
    this.router.navigate(['/booking/choose'], navigationExtras);
  }
  onInputChangesix() {
    this.booking.mobile = this.phone;

    this.token.saveBookingData(this.booking);
  }
  onInputChangethree() {
    // console.log('rooms Value: ' + this.firstName);
    this.booking.firstName = this.firstName;

    this.token.saveBookingData(this.booking);
  }
  onInputChange() {
    this.booking.noOfPersons = this.adults;

    this.token.saveBookingData(this.booking);
  }
  onInputChangeone() {

    this.booking.noOfChildren = this.children;

    this.token.saveBookingData(this.booking);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      // console.log("thei"+ JSON.stringify(this.fromDate))
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date
    ) {
      this.toDate = date;
      this.setSessionData("toDate");
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.setSessionData("fromDate");
  }

  setSessionData(t: any) {
    switch (t) {

      case "toDate":
        this.booking.toDate = this.getDateFormatYearMonthDay(
          this.toDate.day,
          this.toDate.month,
          this.toDate.year
        );
        break;
      case "fromDate":
        this.booking.fromDate = this.getDateFormatYearMonthDay(
          this.fromDate.day,
          this.fromDate.month,
          this.fromDate.year
        );
        break;
      case "adult":
        this.booking.noOfPersons = this.adults;
        break;
      case "child":
        this.booking.noOfChildren = this.children;
        break;
      case "room":
        this.booking.noOfRooms = this.rooms;
        break;
    }

    this.token.saveBookingData(this.booking);
    // this.booking.noOfChildren3To5yrs = this.children3to5;


    // this.bookingCity = this.propertyUrl;
    // this.address.city = this.bookingCity;
    // this.address.suburb = '';

    // this.businessUser.address = this.address;
    // this.token.saveBookingCity(this.bookingCity);

    const navigationExtras: NavigationExtras = {
      queryParams: {
        booking: JSON.stringify(this.booking),

        // business: JSON.stringify(this.businessUser),
      },
    };
    // this.router.navigate(["/booking"], navigationExtras);
  }
    onMonthChange(event: NgbDatepickerNavigateEvent) {
    // Extract the selected month and year from the event
    this.selectedMonth = event.next.month;
    const selectedYear = event.next.year;
    let roomName = "Deluxe Room"
// console.log("date: "+JSON.stringify(event));
this.getPlanPriceByMonthSearch(this.propertyID,this.selectedMonth,roomName);
    // Do something with the selected month and year, such as updating a variable or performing an action
    // console.log(`Selected Month: ${this.selectedMonth}, Year: ${selectedYear}`);
}

  navigate() {
    window.open("https://bookonepms.com/" + this.token.getProperty().seoFriendlyName + "?BookingEngine=true", '_blank');
  }


getPlanPriceByMonthSearch(propertyId:number,month:number,roomName:string){

  this.apiService.getPlanPriceByMonthSearch(propertyId,month,roomName)
  .subscribe(
    (res) => {
      this.resdata = res.body;
      //  console.log("prices " + JSON.stringify (this.resdata[0].roomPrice.toString().substring(0, 1)));

      // this.resdata.forEach(element => {
      //   this.roomPrice = element.roomPrice;
      //   this.todayPrice.push(  this.roomPrice)
      //   console.log("prices sdgfgh" + JSON.stringify(   this.todayPrice));
      // });

    })
}
getDateFormatDayMonthYear(
  day12: number,
  month12: number,
  year12: number
): string {
  const year = year12;
  const date = day12;

  const month = month12;

  let month1;
  let day1;
  if (Number(month) < 10) {
    month1 = `0${month}`;
  } else {
    month1 = `${month}`;
  }
  if (Number(date) < 10) {
    day1 = `0${date}`;
  } else {
    day1 = `${date}`;
  }

  return `${day1}-${month1}-${year}`;
}
  onInputChangetwo() {

    this.booking.noOfRooms = this.rooms;

    this.token.saveBookingData(this.booking);
  }
  onInputChangefour() {
    this.booking.lastName = this.lastName;

    this.token.saveBookingData(this.booking);
  }
  onInputChangefive() {
    this.booking.email = this.email;

    this.token.saveBookingData(this.booking);
  }
  getDateFormatYearMonthDay(
    day12: number,
    month12: number,
    year12: number
  ): string {
    debugger
    const year = year12;
    const date = day12;

    const month = month12;

    let month1;
    let day1;
    if (Number(month) < 10) {
      month1 = `0${month}`;
    } else {
      month1 = `${month}`;
    }
    if (Number(date) < 10) {
      day1 = `0${date}`;
    } else {
      day1 = `${date}`;
    }

    return `${year}-${month1}-${day1}`;
  }
  scrollToSection(section: string): void {
    // First navigate to the section
    this.router.navigate([], { fragment: section }).then(() => {
      // Then force scroll to the section using ViewportScroller
      this.viewportScroller.scrollToAnchor(section);
    });
  }
}
