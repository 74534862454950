<div class="banner_area_type3 bnr_overlay2">
    <div class="holder">
        <div class="hr">
            <div class="vc">
                <div class="banner_section_title align-center">
                    <div class="section_sub_title">
                        <p>about us</p>
                    </div>
                    <h1>A Home Away From Home</h1>
                </div>
            </div>
        </div>
        <div class="hr">
            <div class="vc">
                <div class="bradecrumbe">
                    <ul class="breadcrumb justify-content-center justify-content-cd--center">
                        <li>
                            <a routerLink='/'>
                                Home
                            </a>
                        </li>
                        <li class="active">
                            About Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="holder pt-5"> -->
    <!-- <div class="row"> -->
        <!-- <div class="col-md-6 mb-2 margin_top " > -->
            <!-- <ngx-slick-carousel -->
            <!-- class="camera_wrap carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider" -->
            <!-- #slickModal="slick-carousel" [config]="slideConfig"> -->
            <!-- <div ngxSlickItem *ngFor="let gallery of slideImage" -->
                <!-- class="slide camera_src camerastarted "> -->
                <!-- <img class=" " src="{{gallery.imageUrl}}"  alt="First slide"> -->
                <!-- <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}" -->
                    <!-- style="background-size: cover;" class="item mfp-gallery cover" -->
                    <!-- title="{{gallery.title}}"></a> -->
            <!-- </div> -->
        <!-- </ngx-slick-carousel> -->
        <!-- </div> -->
<!--  -->
        <!-- <div class="col-md-6 mb-5"> -->
            <!-- <div class="overview_text_inner align-center"> -->
                <!-- <div class="section_title align-center"> -->
<!--  -->
                    <!-- <h1>Hotel Shree Sai Wada Shirdi, Maharashtra </h1> -->
                <!-- </div> -->
                <!-- <div class="section_body"> -->
                    <!-- <p>Hotel Shree Sai Wada is a modern  hotel that has affordable and budget -->
                        <!-- friendly accommodation options for Family, Couple & Groups. A unique lodging -->
                        <!-- facility that takes steps to reduce its carbon footprint while giving back to its -->
                        <!-- local community. We provide UV treated water in all the rooms in glass bottle -->
                        <!-- keeping to minimize the plastic usage. We also attempt to digitizes all our -->
                        <!-- operations so that minimum paper usage in our day-day operations to save the trees. -->
                        <!-- All the reservations come with complimentary breakfast options , also other -->
                        <!-- delicious local cuisine meal packages that can be chosen at an extra cost. Most of -->
                        <!-- our food items are being prepared from locally sourced fresh vegetables, fish & -->
                        <!-- meat. We are very passionate about giving an authentic taste to the foods we -->
                        <!-- prepare. A friendly and welcoming staff, who always thrive to ensure our Guests -->
                        <!-- have lovely and memorable staying experiences. We call it “ A Home Away From Home” -->
                        <!-- experience when the Guest leaves us with great feedback. -->
                    <!-- </p> -->
                <!-- </div> -->
            <!-- </div> -->
        <!-- </div> -->
<!--  -->
<!--  -->
    <!-- </div> -->
    <!-- </div> -->





    <div class="about_counter_area ptb-95-70">
      <div class="holder">
          <div class="about_counter_inner">
              <div class="hr">
                  <div class="vc-sd-8 vc-sd-offset-2">
                      <div class="about_counter_text ">
                          <div class="hr">
                              <div class="vc-od-6 vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0">
                                  <img src="assets/images/sharpen_IMG_20230613_134042.jpg" alt="">
                              </div>

                              <div class="vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0">
                                  <p>Hotel Royal Palace offers a blend of modern amenities and traditional hospitality, making it the ideal choice for both business and leisure travelers. It is located in the heart of the city, making it easy to explore nearby attractions. Book your stay with us today and discover all that our hotel has to offer.

                                  </p> <br> <br> <br>
                              </div>
                          </div>
                          <div class="hr">
                              <div class="vc-od-6 vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0">
                                  <div class="about_counter_head align-left">
                                      <h2>LUXURIOUS LOBBY</h2>
                                  </div>
                                  <p>
                                    Hotel Royal Palace strives to provide guests with an unforgettable experience and is committed to sustainability and social responsibility through eco-friendly initiatives such as energy-efficient lighting, recycling programs, and water conservation.
                                                                    </p> <br> <br> <br>
                              </div>

                              <div class="vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0"><img src="assets/images/00000011.jpg" alt="">

                              </div>
                          </div>
                          <div class="hr">
                              <div class="vc-od-6 vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0">
                                  <img src="assets/images/00000013.jpg" alt="">
                              </div>

                              <div class="vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0">
                                  <div class="about_counter_head align-left">

                                      <h2>LUXURIOUS ROOMS</h2>
                                  </div>

                                  <p>
                                    We offer comfortable and spacious rooms with air conditioning, flat-screen TVs, free Wi-Fi, and round-the-clock room service to ensure a comfortable stay. Our rooms come with air conditioning, flat-screen TVs, free Wi-Fi, and more.
                                                                    </p> <br> <br> <br>
                              </div>
                          </div>
                          <!-- <div class="hr"> -->
                              <!-- <div class="vc-od-6 vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0"> -->
                                  <!-- <div class="about_counter_head align-left"> -->

                                      <!-- <h2>Top Notch</h2> -->
                                  <!-- </div> -->

                                  <!-- <p> -->
                                      <!-- The word says it all. u can expect top service and find an enriching experience thoughtfully crafted by associates who truly love what they do. Come experience the madrix madness with celebrity djs and top entertainers guaranteeing you a night to remember! -->
                                  <!-- </p> <br> <br> <br> -->
                              <!-- </div> -->

                              <!-- <div class="vc-sd-8 vc-sd-offset-2 vc-od-6 vc-od-offset-0"><img src="assets/images/service_img2.jpg" alt=""> -->

                              <!-- </div> -->
                          <!-- </div> -->

                          <!-- <div class="about_counter_head align-left">
                              <h2>Lets Talk</h2>
                          </div>
                          <p>
                              Lets Talk is a place to have private meetings. fine dinning with a variety of wines to chose from or to even have a small get-together. where exquisite emersive, impeccable service modern indulgence and refined taste defines every visit.thats not all.. if your looking for something higer up
                          </p>
                          <div class="about_counter_head align-left">

                              <h2>Top-Notch air</h2>
                          </div>

                          <p>
                              Top-Notch air is our sky lounge with majestic settings carefully curated cocktails and cuisines that will get your palate excited.our brunches are definitely something to look forward too.
                          </p>
                          <div class="about_counter_head align-left">

                              <h2>Top Notch</h2>
                          </div>

                          <p>
                              The word says it all. u can expect top service and find an enriching experience thoughtfully crafted by associates who truly love what they do. Come experience the madrix madness with celebrity djs and top entertainers guaranteeing you a night to remember!
                          </p> -->
                          <br>
                          <p>
                            Hotel Royal Palace is the perfect destination for a comfortable and convenient stay . Book your stay today and experience the perfect blend of modern amenities and traditional hospitality.

                          </p>
                      </div>
                  </div>
                  <!-- <div class="vc-cd-6 vc-sd-5 vc-od-2 vc-sd-offset-1 vc-od-offset-2">
                          <div class="single_counter_box align-center">
                              <div class="counter_num">
                                  <div class="counter_taxt">
                                      <span>client</span>
                                  </div>
                                  <span class="counter">190</span>
                              </div>
                          </div>
                      </div>
                      <div class="vc-cd-6 vc-sd-5 vc-od-2">
                          <div class="single_counter_box align-center">
                              <div class="counter_num">
                                  <div class="counter_taxt">
                                      <span>rooms</span>
                                  </div>
                                  <span class="counter">200</span>
                              </div>
                          </div>
                      </div>
                      <div class="vc-cd-6 vc-sd-5 vc-sd-offset-1 vc-od-2 vc-od-offset-0">
                          <div class="single_counter_box align-center">
                              <div class="counter_num">
                                  <div class="counter_taxt">
                                      <span>stuff</span>
                                  </div>
                                  <span class="counter">800</span>
                              </div>
                          </div>
                      </div>
                      <div class="vc-cd-6 vc-sd-5 vc-od-2">
                          <div class="single_counter_box align-center">
                              <div class="counter_num">
                                  <div class="counter_taxt">
                                      <span>award</span>
                                  </div>
                                  <span class="counter">150</span>
                              </div>
                          </div>
                      </div> -->
              </div>
          </div>
      </div>
  </div>
