<div class="cmn_banner_area banner_type7 bnr_overlay2">
  <div class="holder">
    <div class="hr">
      <div class="vc">

        <div class="section_title align-center">
          <div class="section_sub_title ">
            <p class="text-center">hotel service</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        <div class="bradecrumbe">
          <ul class="breadcrumb justify-content-center justify-content-cd--center">
            <li>
              <a routerLink="/home">
                Home
              </a>
            </li>
            <li class="active">
              Hotel Service
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="holder pt-5">
  <div class="row">
    <div class="col-md-6 mb-2">
      <img src="assets/images/aboutimage.png" alt="">
    </div>

    <div class="col-md-6 mb-5">
      <div class="overview_text_inner align-center">
        <div class="section_title align-center">

          <h1>Hotel Shree Sai Wada  Shirdi, Maharashtra </h1>
        </div>
        <div class="section_body">
          <p>Hotel Shree Sai Wada is a  hotel in Shirdi, Maharashtra  just which is a best place for travel and tourism.
            We are a 29 rooms property with a configuration of :<br>
            <strong> 22 Rooms :</strong> Deluxe Room can accommodate 4 pax in each room has a Queen size bed in the hall
            and 2 single beds in the bedroom and 1 washroom with Hot water facility Shower, Television , Wifi , Air
            Conditioner, Bath Towels and Hand towels .<br><strong>
              5 Rooms :</strong>Super Deluxe Room  can accommodate 2 pax has a Queen size bed and 1 washroom with
            Hot water facility Shower, Television , Wifi , Air Conditioner, Bath Towels and Hand towels .<br>
            <strong>2 Rooms :</strong> Suite Room can accommodate 4 pax in each room has a Queen size bed in the hall
            and 2 single beds in the bedroom and 1 washroom with Hot water facility Shower, Television , Wifi , Air
            Conditioner, Bath Towels and Hand towels .<br> -->
            <!-- <br><strong> Dormitory :</strong> <br>
            Can accommodate 4 pax on bed and 2 extra mattress can be accommodated has 2 washrooms . AC, Television set
            is not available in the dormitory .
            <br><strong>Restaurant :</strong><br> We have a restaurant serving vegetarian and Non vegetarian food the
            restaurant has a seating capacity of 40 to 50 persons , Nescafe vending machine available .
          </p> -->
        <!-- </div>
      </div>
    </div>


  </div>
  <div class="row">
    <div class="col-md-6 mb-5">
      <div class="overview_text_inner align-center">
        <div class="section_title align-center">

          <h1>Group Bookings</h1>
        </div>
        <div class="section_body">
          <p>We have 29 rooms where 22 rooms are Deluxe Room and 5 are Super Deluxe Rooms and 2 rooms are suite room.
            Beds available for 36 guests and 10 extra mattresses can be provided. Total 46 pax can be easily
            accommodated. <br> -->
            <!-- Rate for groups Bed and Breakfast : <br> -->
            <!-- <strong> Group Rates : </strong>
            1,500/- plus tax ( 12% gst ) per person per day includes stay and breakfast .<br>
            <strong>Vegetarian Menu Package :</strong> 1,000/- plus tax ( 10 %)<br>
            <strong>Non Vegetarian Meal Package :</strong> 1,200/- plus tax ( 10%) Includes non veg only in dinner .<br>
            <strong>Non Vegetarian Meal Package :</strong> 1,500/- plus taxes ( 10%) <br>
            Includes non veg in both meals .</p> <br> -->


        <!-- </div>
      </div>
    </div>

    <div class="col-md-6 mb-2">
      <img src="assets/images/aboutimage2.png" alt="">
    </div>
  </div> -->

  <div class="service_area_type2 ptb-95-70 pt-5">
    <div class="holder">
      <div class="service_inner">
        <div class="hr">
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-cutlery" aria-hidden="true"></i>
              <h3>Restaurant</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-wifi"></i>
              <h3>wi-fi</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service align-center mb-30">
              <i class="fa fa-bus" aria-hidden="true"></i>
              <h3>Pick Up and Drop</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-bed" aria-hidden="true"></i>
              <h3>Room Service</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
        </div>
        <div class="hr">

          <div class="vc-sd-6 vc-od-3">
            <div class="single_service align-center mb-30">
              <i class="fa fa-television" aria-hidden="true"></i>
              <h3>LED TV</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-camera" aria-hidden="true"></i>
              <h3>Security</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-car" aria-hidden="true"></i>
              <h3>Car parking</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>




          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-glass" aria-hidden="true"></i>
              <h3>Bar service</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>



          <!-- <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fas fa-hamburger" aria-hidden="true"></i>
              <h3>Food Service</h3>
            </div>
          </div> -->


        </div>
      </div>
    </div>
  </div>
