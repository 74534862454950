import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  slideImage = [
    {
      imageUrl: 'assets/images/aboutimage3.png',
      title: '',
    },
    {
      imageUrl: 'assets/images/aboutimage4.png',
      title:'',
    },
    {
      imageUrl: 'assets/images/aboutimage5.png',
      title:'',
    }
  ];
  slideConfig = {
    dots: true,
    dotsData: true,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,

    responsive: [
      {
        breakpoint: 1367,
        settings: {
          centerPadding: '0',
          dots: false
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
    ],
  };
  constructor(
    public token: TokenStorage,
  ) { }

  ngOnInit(): void {
  }

}
