<body id="top" data-new-gr-c-s-check-loaded="14.1116.0" data-gr-ext-installed="" monica-version="2.3.1" monica-id="ofpnmcalabcbjgholdjcjblkibolbppb">



<!--<marquee direction="left" height="20px" style="color:#ff0307; font-size:18px; font-family:serif;">-->
<!--    It's mandatory to all the guests to carry a Covid-19 (RT-PCR) Negative report done within 72 hours for their stay-->
<!--    </marquee>-->
    <header class="navbar-fixed">




 <div id="boxes">
<div style=" top:104px !important; left: 50%; display: none;" id="dialog" class="window">
<div id="san">
<a href="#" class="close agree"><img src="assets/close-icon.png" width="25" style="float:right; margin-right: -25px; margin-top: -20px;"></a>
<img src="assets/images/popupimg.jpeg" width="450">
</div>
</div>
<div style="width: 2478px; font-size: 32pt; color:white; height: 1202px; display: none; opacity: 0.4;" id="mask"></div>
</div>



</header>

<div class="cmn_banner_area banner_type7 bnr_overlay2">
    <div class="holder">
        <div class="hr">
            <div class="vc">
                <div class="section_title align-center">
                    <div class="section_sub_title">
                        <p>hotel Polocies</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="hr">
            <div class="vc">
                <div class="bradecrumbe">
                    <ul class="breadcrumb justify-content-center justify-content-cd--center">
                        <li>
                            <a href="index.php">
                                Home
                            </a>
                        </li>
                        <li class="active">
                            Hotel Policies
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="service_area_type2 ptb-95-70">
    <div class="holder">
        <div class="service_inner">
            <h4>ONLINE RESERVATION PROCEDURE AND POLICIES</h4>
            <p>Website enables you to check the accommodation offerings and availability of rooms, on completion of the reservation process the reservations facility will transmit an online confirmation of your reservation. A reservation is only valid when a reservation number has been issued.</p>

            <h4>HOTEL PRICES</h4>
            <p>The prices indicated on the website for the rooms are subject to availability. Breakfast is only included if indicated in the rate policy. Taxes and any other charges are only included if indicated in the rate policy.</p>
            <h4>PAYMENT</h4>
            <p>It will be necessary for you to book with valid credit/debit card details to secure a room reservation. The credit card may then be used by the resort for payment of a deposit as advised at the time of booking or as a guarantee for a future payment, in the case of a no-show, a cancellation or change to the reservation.</p>
            <h4>
                RATE/RESERVATION VALIDITY
            </h4>
            <p>
                Reservation confirmations are provided to you solely for your convenience. The resort retains official records of reservation transactions, including details of dates of stay and room rates. In the event of discrepancies, alterations, modifications or variations between this confirmation and our official records, resorts official records shall prevail. Tampering with this confirmation to alter the room rate or any other reservation information is strictly prohibited and will have legal consequences.
            </p>
            <h4>
                EARLY DEPARTURE
            </h4>
            <p>
              In case of early check-out, the resort will not make any refund and the guest will be charged for the full stay.
            </p>
            <h4>
                CURRENCY
            </h4>
            <p>
                Reservations will be charged in the hotel’s local currency as shown on the website or given over the telephone at the time of booking. Any currency conversion is for guidance only. Any deposits or pre-payments will be deducted from the final reservation invoice in the hotel’s local currency.

A refund of a deposit or prepayment, if any, will be subject to the exchange rate applied by your payment card provider at the time of any such refund and may differ from the exchange rate applied at the time such deposit or prepayment was made.
            </p>
            <h4>
                PRE-PAID RESERVATIONS
            </h4>
            <p>
                Some rate plans require prepayment in full and are non-cancellable, non-refundable, non-changeable and non-transferable.
                This will be identified in the rate description. For these rate plans, once you make a reservation your credit card will be charged for the full
                amount. Credit will not be given for any unused reservation and the amount paid by you for such reservation cannot be used toward any other/future reservation.
            </p>OVERBOOKING
            <p>In the event that a guest cannot be accommodated due to the resorts overbooking or an unforeseen circumstance, the resort will attempt to accommodate guests, at its expense, at a comparable property at its sole discretion in the area for the night(s) for which the resort is unable to accommodate such guests.</p>
            <h4>
                SPECIAL REQUESTS
            </h4>
            <p>
                Resort will strive to honor any request for specific room types or bed types. Until specific confirmation is issued then your request cannot be guaranteed and neither the resort can guarantee that your request will be honored.
            </p>
            <h4>
                PETS
            </h4>
            <p>
                The Resort is not pet friendly. Hence, one should not book if you are travelling with pets.
            </p>
            <h4>GUEST’S OBLIGATIONS</h4>
            <p>
                You agree to the terms and conditions of purchase imposed by the resort. You also agree that you are fully responsible and shall pay for all charges, fees, duties, taxes, assessments and other amounts arising out of your reservation when due, including additional taxes and fees that may not be included in the quoted rate. You understand that any violation of the resort’s conditions may result in cancellation of your reservation and you forfeiting any monies paid for such reservation and the resort debiting your account for any costs it incurs as a result of such violation.
            </p>
            <h4>FORCE MAJEURE</h4>
            <p>
                The resort will not be liable for any change or cancellation of any reservation that is caused, in whole or in part, by events, occurrences, or causes beyond the control of the resort. Such events, occurrences, or causes include, without limitation, acts of God, terrorist activities, weather, strikes, lockouts, riots, acts of war, earthquake, volcanic activity, ash clouds, tsunamis, fire and explosions.
            </p>
            <h4>
                GOVERNING LAW
            </h4>
            <p>These reservation terms and conditions are governed by the laws of Odish and the courts of  Odisha only shall have exclusive jurisdiction in relation to any dispute in relation there to.

As per the government regulations, all travellers including children (mandatory) must carry their I.D. proofs for smooth check-in. The resort has the right to refuse the check-in , if the guests fails to provide their I.D. and the guest will be responsible to bear the charges of the room as stated in the cancellation policy.</p>
<h4>CHECK IN AND CHECK OUT TIME</h4>
<p>
    Standard check-in time is 2.00 PM and standard check-out time is 12.00 AM. The guest is requested to kindly inform the resort the time of arrival one day in advance for smooth check-in.
</p>
<h4>MEAL PLAN</h4>
<p>
    Buffet breakfast and dinner is available to the guests at the restaurant. The timing for buffet breakfast is 8.00 AM to 10.30 AM and dinner is 8.00 PM to 10.30 PM. Any buffet meal will only be served in the restaurant as per timings of the resort and not in the rooms. If the guest, for any reason could not enjoy their meal plan, the resort is not responsible for any compensation. However, the guest can order room service at extra cost as per the prevailing menu prices without any discount.
</p>
<h4>GOVERNING LAW</h4>
<p>These reservation terms and conditions are governed by the laws of Odisha and the courts of Odisha only shall have exclusive jurisdiction in relation to any dispute in relation thereto.</p>
<h4>
    CONSUMPTION OF ALCOHOL
</h4>
<p>As per Himachal Pradesh excise policy personal liquor can be consumed in the allocated guest rooms only. No public area, special venue, terrace and hall will be provided for consuming personal liquor.</p>
<h4>
    CANCELLATION / REFUND POLICY
</h4>
<p>
    Cancellation before 7 days  of check-In : 75% Refund<br>
    Cancellation between 3 days of check-in:
40% Refund<br>
Note: In case of any refund, 3% cancellation charge will be applicable on the total amount deposited.


</p>
        </div>
    </div>
</div>



<a href="#top" class="scroll_top" data-smooth-scroll="" data-animate-time="500" style="display: none;">
    <i class="fa fa-angle-double-up"></i>
</a>


<script src="assets/js/jquery-1.12.1.min.js"></script>

<script src="assets/js/offra.min.js"></script>

<script src="assets/js/owl.carousel.min.js"></script>

<script src="assets/js/isotope.pkgd.min.js"></script>

<script src="assets/js/jquery-ui.min.js"></script>

<script src="assets/js/masonry.pkgd.min.js"></script>

<script src="assets/js/jquery.waypoints.min.js"></script>

<script src="assets/js/jquery.counterup.min.js"></script>

<script src="assets/js/jquery.easing.min.js"></script>

<script src="assets/js/camera.min.js"></script>

<script src="assets/js/venobox.min.js"></script>

<script src="assets/js/jquery.flexslider.js"></script>

<!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB2D8wrWMY3XZnuHO6C31uq90JiuaFzGws"></script> -->

<script src="assets/js/main.js"></script>


<div id="monica-content-root" class="monica-widget"></div></body>
