<div class="cmn_banner_area banner_type13 bnr_overlay2">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="section_title align-center">
          <div class="section_sub_title">
            <p>contact</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        <div class="bradecrumbe">
          <ul class="breadcrumb justify-content-center justify-content-cd--center">
            <li>
              <a routerLink="/home"> Home </a>
            </li>
            <li class="active">contact</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contact_N_map_area ptb-100" >
  <div class="holder">
    <div class="hr">
      <div class="vc-sd-6">
        <div class="contact_inner">
          <h2>Drop Us A Message</h2>
          <p>If You Have Any Query , Don't Hesitate to Contact With Us</p>
          <form *ngIf="success == false" #contactForm="ngForm" (ngSubmit)="submitForm(contactForm)" role="form">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-field">
                  <label>Full Name*</label>
                  <input type="text" name="name" [(ngModel)]="name" class="form-control" placeholder="Full Name" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-field">
                  <label>Your Email*</label>
                  <input type="email" required name="email" email ngModel class="form-control" placeholder="Email" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-field">
                  <label>Subject*</label>
                  <input type="text" class="form-control" name="subject" [formControl]="subject" [(ngModel)]="subjects"
                    required placeholder="Subject" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-field">
                  <label>Your Message</label>
                  <textarea require class="form-control" d name="message" ngModel rows="5"></textarea>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-submit">
                  <button type="submit" [disabled]="!contactForm.valid" class="btn" style="background-color: #ffb115">
                    Send Message <i class="la la-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div *ngIf="success" class="alert alert-success alert-dismissible fade show" role="alert" [@animate]="{
              value: '*',
              params: {
                y: '-120px',
                opacity: '0',
                delay: '100ms',
                duration: '400ms'
              }
            }">
            <button type="button" class="close" (click)="success = false">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>Your Message Sent succefully</strong>
          </div>
        </div>
      </div>
      <div class="vc-sd-6">
        <div class="map_inner">
          <div>
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8005.338168388197!2d73.41943224097484!3d18.752796311403696!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7348c6b190f83a44!2sFlora%20Ecostay!5e0!3m2!1sen!2sin!4v1637954181549!5m2!1sen!2sin"
              width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy">
            </iframe> -->
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3737.9284866537637!2d85.90294207428991!3d20.46813020679987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190dec32e01951%3A0x331e4ed5c1e44d33!2sHotel%20New%20Jasmine!5e0!3m2!1sen!2sin!4v1706356791043!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.396222640402!2d76.1812375748866!3d17.53631248337653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc609e1bea9323d%3A0xb56d7c2d8045087e!2sD%20GREEN%20VILLAGE%20RESORT!5e0!3m2!1sen!2sin!4v1672140917957!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
          </div>
          <!-- <div>

            <iframe
              src="{{token.getProperty().latitude}}"
              width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div> -->

          <div class="map_info">
            <div class="hr">
              <div class="col-12">
                <!-- <p>{{token.getProperty().address.streetName}} {{token.getProperty().address.streetNumber}}
                  {{token.getProperty().address.suburb}} {{token.getProperty().address.city}}
                  {{token.getProperty().address.state}} {{token.getProperty().address.country}}
                  {{token.getProperty().address.postcode}}
                </p> -->
                <div class="icon_numb">

                  <h6><span class="info_icon"><i class="fa fa-home"></i></span><strong>Address:</strong>
                    {{ property.address.streetName }}
                    {{ property.address.streetNumber }}
                    {{ property.address.suburb }}, {{ property.address.city }},
                    {{ property.address.state }},
                    {{ property.address.country }},
                    {{ property.address.postcode }}</h6>
                </div>

                <div class="icon_numb">


                  <h5> <span class="info_icon"><i class="fa fa-mobile"></i></span><a href="tel:+{{token.getProperty().mobile}}">{{token.getProperty().mobile}}</a></h5>

                </div>
                <div class="icon_numb">

                <h5><span class="info_icon"><i class="fa fa-envelope"></i></span><a routerLink="mailto::hotelnewjasmine@gmail.com">hotelnewjasmine@gmail.com</a></h5>
                </div>
              </div>
              <!-- <div class="vc-sd-11 vc-sd-offset-1 vc-od-6 vc-od-offset-0 no-gap">
                <p>
                  <span>phone number:</span><a href="tel:+{{ token.getProperty().mobile }}">{{
                    token.getProperty().mobile
                  }}</a>
                </p>



                <p class="highlt">24/7 Available</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
