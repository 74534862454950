<div class="footer_styling"
     [ngStyle]="{
       'background-image': 'linear-gradient(' + gradientColor + ', ' + gradientColor + '), url(' + footerBackgroundImageUrl + ')'
     }">

  <div class="row rowdiv_footer" style="margin: 0; padding: 20px;color: white;">
    <div class="col-md-4 text-center text-md-left">
      <div class="logo mb-3">
        <img src= {{token?.getProperty()?.logoUrl}} class="logo" alt="" style="height: 100px">
      </div>
      <!-- <div class="social-icons mb-3">
        <a href="#"><img style="padding: 5px 8px; height: 50px; width: 50px;" src="assets/images/instagram.png" alt="Instagram"></a>
        <a href="#"><img style="padding: 5px 8px; height: 40px; width: 50px;" src="assets/images/fb.png" alt="Facebook"></a>
        <a href="#"><img style="padding: 5px 8px; height: 40px; width: 50px;" src="assets/images/linkdin.png" alt="LinkedIn"></a>
        <a href="#"><img style="padding: 5px 8px; height: 40px; width: 50px;" src="assets/images/youtube.png" alt="YouTube"></a>
      </div> -->
      <button class="book-btn btn btn-primary" style="color: black;" (click)="bookYourStay()">Book Your Stay</button>
    </div>
    <div class="col-md-4 lnk">
      <h5 class="contact-footer" style="font-weight: 700;color: white;">Quick Links</h5>
      <ul class="services-list list-unstyled">
        <li><a style="color: white;" (click)="scrollToSection('room')">Rooms</a></li>
        <li><a style="color: white;" (click)="scrollToSection('service')">Services</a></li>
        <li><a style="color: white;" (click)="scrollToSection('testimonials')">Testimonials</a></li>
        <li><a style="color: white;" (click)="scrollToSection('amenities')">Amenities</a></li>
      </ul>
    </div>
    <div class="col-md-4 contact-info">
      <h5 class="contact-footer" style="font-weight: 700;color: white;">Contact Us</h5>
      <p class="contact-footer" style="color: white;"><i class="fa fa-phone"></i>{{property?.mobile}}</p>
      <p class="contact-footer" style="color: white;"><i class="fa fa-envelope"></i>{{property?.email}}</p>
    </div>
  </div>
</div>

<a routerLink="/top" class="scroll_top" (click)="scroll_top()" data-smooth-scroll data-animate-time="500" style="position: fixed; bottom: 20px; right: 20px; background-color: #f0bf4c; color: black; padding: 10px">
  <i class="fa fa-angle-double-up"></i>
</a>
