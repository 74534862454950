import {  Host, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Booking } from './model/booking';
import { BookingDetails } from './model/bookingdetails';
import { Msg } from './model/msg';
import { Payment } from './model/payment';
import { Property } from './model/property';
import { Customer } from './model/customer';
import { MessageDto } from './model/MessageDto';
import { APP_ID } from './app.component';
import { Room } from './model/room';
import { GuestReview } from './shared/models/guestReview';
import { GoogleReview } from './model/googleReview';
import { BusinessUser } from './model/user';
import { DeliveryOption } from './model/deliveryOption';
import { ProductGroup } from './model/productGroup';
import { OrderDto } from './model/orderDto';


export const API_URL = environment.apiUrl + '/api/thm';

export const API_URL_PROMOTION = environment.apiUrlPromotion;
export const API_URL2 = environment.apiUrl;
export const SMS_NUMBER = '+1 956 903 2629';
//const API_URL2 = 'https://localhost:8080';
//const API_URL = 'http://localhost:8080/api/website';

export const PROPERTY_ID = environment.propertyId
;
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  processPayPalPayment(payment: Payment) {
    return this.http.post(API_URL2 + '/api/payment/create', payment, { observe: 'response' });
  }
  getAllBookingsByHost(host: Host) {
    return this.http.post<Booking[]>(API_URL2 + '/api/booking/findAll', host, { observe: 'response' });
  }
  getPlanPriceByMonthSearch(propertyId:number,month:number,roomName:string) {
    // this.setApi();
    return this.http.get<any[]>(API_URL + '/api/website/getRatesAndAvailabilityByProperty/' + propertyId + "?month="+month + "&roomName =" + roomName,  { observe: 'response' });
  }
  getBookingDetailsByIdAndEmail(booking: Booking) {
    return this.http.get<BookingDetails>(API_URL + '/findBookingByIdAndEmail?BookingReferenceNumber=' + booking.propertyReservationNumber + '&BookingEmail=' + booking.email, { observe: 'response' });
  }
  getBookingDetails(bookingNumber: any, bookingEmail: string) {

    return this.http.get <any>(API_URL + '/findBookingByIdAndEmail?BookingReferenceNumber=' + bookingNumber + '&BookingEmail=' + bookingEmail,  { observe: 'response' });
  }
  authorisationToken(message: MessageDto) {
    let headers = new HttpHeaders({
      'APP_ID': APP_ID
  });
    return this.http.post<MessageDto[]>(API_URL2 + '/api/message/authorisationToken', message, { headers: headers });
  }
  send(message: MessageDto) {
    let headers = new HttpHeaders({
      'APP_ID': APP_ID
  });
    return this.http.post<MessageDto[]>(API_URL2 + '/api/message/send', message, { headers: headers });
  }
  verifyAuthorisationToken(message: MessageDto) {
    let headers = new HttpHeaders({
      'APP_ID': APP_ID
  });
    return this.http.post<MessageDto[]>(API_URL2 + '/api/message/verifyAuthorisationToken', message, { headers: headers });
  }
  getCustomerDetailsByEmail(email: string) {
    return this.http.get<Customer>(API_URL + '/email/' + email + '/', { observe: 'response' });
  }
  getCustomerDetailsByMobile(mobile: string) {
    return this.http.get<Customer>(API_URL + '/mobile/' + mobile, { observe: 'response' });
  }

  paymentIntent(paymentDetails: Payment) {
    return this.http.post<Payment>(API_URL + '/paymentIntent', paymentDetails, { observe: 'response' });
}
findByPropertyId(id: number) {
  return this.http.get<BusinessUser>(API_URL + '/findByPropertyId/' + id, { observe: 'response' });
}
  getPropertyDetailsByPropertyId(propertyId: number) {
    return this.http.get<Property>(API_URL + '/findById/' + propertyId, { observe: 'response' });
  }
  getGoogleReview(propertyId: number) {
    return this.http.get<any>(API_URL + '/getGoogleReviews?PropertyId=' + propertyId, { observe: 'response' });
  }
  getRoomDetailsByPropertyId(propertyId: number) {
    return this.http.get<Room[]>(API_URL + '/findAllRoomsByPropertyId/' + propertyId, { observe: 'response' });
  }

  getRoomDetailsByPropertyIdAndDate(propertyId: number, fromDate: string, toDate: string) {
    return this.http.get<Room[]>(API_URL + '/getAllRoomsByDate?PropertyId=' + propertyId + '&FromDate=' + fromDate + '&ToDate=' + toDate, { observe: 'response' });
  }

  checkAvailability(booking: Booking) {
    return this.http.post<Booking>(API_URL + '/checkAvailability', booking, { observe: 'response' });
  }
  checkAvailabilityByID(booking: Booking) {
    return this.http.get<any>(API_URL + '/checkAvailability/' + PROPERTY_ID + '?fromDate=' + booking.fromDate + '&toDate=' + booking.toDate + '&noOfRooms=' + booking.noOfRooms + '&noOfPersons=' + booking.noOfPersons + '&noOfChildren=' + booking.noOfChildren + '', { observe: 'response' });
  }
  processPayment(paymentDetails: Payment) {
    return this.http.post<Payment>(API_URL + '/processPayment', paymentDetails, { observe: 'response' });
  }
  createBooking(booking: Booking) {
    return this.http.post<Booking>(API_URL + '/booking', booking, { observe: 'response' });
  }
  sendTextMessage(message: Msg) {
    return this.http.post<Msg>(API_URL + '/message/send', message, { observe: 'response' });
  }
  savePayment(paymentDetails: Payment) {
    return this.http.post<Payment>(API_URL + '/savePayment', paymentDetails, { observe: 'response' });
  }
  getGoogleReviews() {
    return this.http.get<GuestReview[]>(API_URL + '/getGoogleReviews?PropertyId=' + PROPERTY_ID, { observe: 'response' });
  }
  getOfferDetailsBySeoFriendlyName( seoName: string) {
    return this.http.get<any>(API_URL_PROMOTION + '/api/offer/findBySeofriendlyName/'+seoName+'/',{ observe: 'response' });
  }
  getAllReview(propertyId: number) {
    return this.http.get<GoogleReview[]>(API_URL + '/getGoogleReviews?PropertyId=' + propertyId, { observe: 'response' });
  }
  getBusinessBranch(id) {
    return this.http.get<any[]>(API_URL + '/' + id + '/branches', { observe: 'response' });
  }
  getDeliveryOption(businessServiceId : number){
    return this.http.get<DeliveryOption[]>(API_URL + '/'+businessServiceId+'/deliveryOptions' , { observe: 'response' });
  }
  findPropertyBySEOName(seoFriendlyName: string) {
    return this.http.get<BusinessUser>(API_URL + '/findByPropertyBySEOFriendlyName/' + seoFriendlyName, { observe: 'response' });
  }
  findProductsByBusinessServiceId(businessServiceId: number) {
    return this.http.get<ProductGroup[]>(API_URL + '/getProductList/' + businessServiceId, { observe: 'response' });
  }
  order(order: OrderDto){
    return this.http.post<OrderDto>(API_URL2 + '/api/order', order, { observe: 'response' });
  }
}
