<div class="cmn_banner_area banner_type14 bnr_overlay2">
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="section_title align-center">
                  <div class="section_sub_title">
                      <p>Gallery</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink="/home">
                              Home
                          </a>
                      </li>
                      <li class="active">
                          Gallery
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>


<div class="gallery_area_type1 ptb-70">
  <div class="holder">
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_filter align-center">
                  <!-- <ul class="filter" id="grid">
                      <li class="active" data-filter="*">
                          <span class="common_btn">All Photos</span>
                      </li>
                      <li data-filter=".rooms">
                          <span class="common_btn">Rooms</span>
                      </li>
                      <li data-filter=".restaurant" class="">
                          <span class="common_btn">Restaurant & Club</span>
                      </li>
                      <li data-filter=".hotel">
                          <span class="common_btn">Hotel</span>
                      </li>
                  </ul> -->
              </div>
          </div>
      </div>
      <div class="gallery_area_type1 ptb-70">
        <div class="holder">
            <div class="hr">
                <div class="vc-sd-12">
                    <div class="gallery_filter align-center">
                        <ul class="filter" id="grid">
                            <li class="active" data-filter="*">
                                <span class="common_btn">Our Gallery</span>
                            </li>
                        </ul>
                    </div>
                </div>



            </div>
            <div class="hr">
    <div class="vc-sd-12">
        <div class="gallery_item align-center">
            <div class="gallery_item1 row" data-toggle="modal" data-target="#largeModal">

                <!-- Move the ngFor loop to this container div -->
                <div *ngFor="let gallery of galleryImage" class=" grid-item width-1-4 {{gallery.class}}">
                    <div class="item_content">
                        <div class="single_grid_item">
                            <img class="mobile_view_imgsize" src="{{gallery.imageURL}}" alt="gallery">
                            <a routerLink="{{gallery.imageURL}}" class="singel_item_hover_content restaurant_menu_image" data-gall="smg">
                                <h5>{{gallery.title}}</h5>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

            
           
        </div>
      </div>
      
  </div>
   <!-- modal -->
   <div class="modal fade" id="largeModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content modal_class">
        <div class="modal-body">
           <!-- carousel -->
          <div
               id='carouselExampleIndicators'
               class='carousel slide'
               data-ride='carousel'
               >
            <ol class='carousel-indicators'>
              <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='0'
                  class='active'
                  ></li>
              <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='1'
                  ></li>
              <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='2'
                  ></li>
                  <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='3'
                  ></li>
                  <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='4'
                  ></li>
                  <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='5'
                  ></li>
            </ol>
            <div class='carousel-inner'>
              <div class='carousel-item active'>
                <img class='img-size' src='assets/images/royal2.jpg' alt='First slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/royal9.png' alt='Second slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/royal14.jpg' alt='Second slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/royal10.jpeg' alt='Third slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/royal8.jpg' alt='Third slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/royal5.jpg' alt='Third slide' />
              </div>
            </div>
            <a
               class='carousel-control-prev'
               href='#carouselExampleIndicators'
               role='button'
               data-slide='prev'
               >
              <span class='carousel-control-prev-icon'
                    aria-hidden='true'
                    ></span>
              <span class='sr-only'>Previous</span>
            </a>
            <a
               class='carousel-control-next'
               href='#carouselExampleIndicators'
               role='button'
               data-slide='next'
               >
              <span
                    class='carousel-control-next-icon'
                    aria-hidden='true'
                    ></span>
              <span class='sr-only'>Next</span>
            </a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>






























<!-- <div class="gallery_area_type1 ptb-70">
  <div class="holder">
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_filter align-center">
                  <ul class="filter" id="grid">
                      <li class="active" data-filter="*">
                          <span class="common_btn">All Photos</span>
                      </li>
                      <li data-filter=".rooms">
                          <span class="common_btn">Rooms</span>
                      </li>
                      <li data-filter=".restaurant" class="">
                          <span class="common_btn">Restaurant &amp; Club</span>
                      </li>
                      <li data-filter=".hotel">
                          <span class="common_btn">Hotel</span>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_item align-center">
                  <div class="grid gallery_item1" style="position: relative; height: 2332px;">
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 0%; top: 0px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img1.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img1.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 24.9593%; top: 0px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img2.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img2.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 50%; top: 0px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img3.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img3.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 74.9593%; top: 0px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img4.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img4.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 0%; top: 212px;">
                          <div class="item_content">
                              <div class="single_grid_item ">
                                  <img src="assets/images/gallery_img5.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img5.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 24.9593%; top: 212px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img6.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img6.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 50%; top: 212px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img7.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img7.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design hotel" style="position: absolute; left: 74.9593%; top: 212px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img8.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img8.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 0%; top: 424px;">
                          <div class="item_content">
                              <div class="single_grid_item ">
                                  <img src="assets/images/gallery_img9.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img9.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Hotel</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 24.9593%; top: 424px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img10.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img10.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 hotel" style="position: absolute; left: 50%; top: 424px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img11.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img11.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design rooms" style="position: absolute; left: 74.9593%; top: 424px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img12.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img12.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Room</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design rooms" style="position: absolute; left: 0%; top: 636px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img13.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img13.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Room</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design rooms" style="position: absolute; left: 24.9593%; top: 636px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img14.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img14.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Room</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design rooms" style="position: absolute; left: 50%; top: 636px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img15.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img15.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Room</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design rooms" style="position: absolute; left: 74.9593%; top: 636px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img16.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img16.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Room</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design rooms" style="position: absolute; left: 0%; top: 848px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img17.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img17.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Room</h5>

                                  </a>
                              </div>
                          </div>
                      </div>

                      <div class="grid-item width-1-4 design rooms" style="position: absolute; left: 24.9593%; top: 848px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img18.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img18.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Room</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 50%; top: 848px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img19.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img19.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 74.9593%; top: 848px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img20.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img20.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 0%; top: 1060px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img21.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img21.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 24.9593%; top: 1060px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img22.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img22.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 50%; top: 1060px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img23.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img23.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 74.9593%; top: 1060px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img24.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img24.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 0%; top: 1272px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img25.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img25.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 24.9593%; top: 1272px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img26.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img26.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 50%; top: 1272px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img27.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img27.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 74.9593%; top: 1272px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img28.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img28.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 0%; top: 1484px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img29.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img29.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch Air</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 24.9593%; top: 1484px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img30.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img30.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 50%; top: 1484px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img31.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img31.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 74.9593%; top: 1484px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img32.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img32.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 0%; top: 1696px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img33.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img33.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 24.9593%; top: 1696px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img34.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img34.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 50%; top: 1696px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img35.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img35.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>

                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 74.9593%; top: 1696px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img36.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img36.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 0%; top: 1908px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img37.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img37.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 24.9593%; top: 1908px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img38.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img38.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 50%; top: 1908px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img39.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img39.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 74.9593%; top: 1908px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img40.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img40.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="grid-item width-1-4 design restaurant" style="position: absolute; left: 0%; top: 2120px;">
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="assets/images/gallery_img41.jpg" alt="gallery">
                                  <a href="assets/images/gallery_img41.jpg" class="singel_item_hover_content restaurant_menu_image vbox-item" data-gall="smg">
                                      <h5>Top Notch</h5>

                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->
