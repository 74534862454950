<div id="amenities">
  <!-- <div class="vc">
    <div class="pool-lb" style="text-align: center;font-size: 30px;">Swimming Pool</div>
    <div class="pool-container">
      <div class="pool-text">
        <h3 style="padding-bottom: 20px;font-size: 20px;">Dive into relaxation at our inviting swimming pool. Whether you prefer a refreshing dip or simply lounging poolside, our oasis offers the perfect escape.</h3>
        <ul class="points">
          <li class="dotted-list">Open daily from 7:00 AM to 9:00 PM.</li>
          <li class="dotted-list">Children under 10 must be accompanied by an adult.</li>
          <li class="dotted-list">Lounge chairs and towels available on a first-come, first-served basis.</li>
        </ul>
      </div>
      <div class="pool-image">
        <img src="assets/images/swimming pool.png" alt="Swimming Pool">
      </div>
    </div>
  </div> -->

  <!-- <div class="vc section-alternate reverse">
    <div class="pool-lb" style="text-align: center;font-size: 30px;">Fitness Centre</div>
    <div class="pool-container-reverse">
      <div class="pool-image">
        <img src="assets/images/fitness center.png" alt="Fitness Center">
      </div>
      <div class="pool-text">
        <h3 style="padding-bottom: 20px;font-size: 20px;">Maintain your fitness routine during your stay at our well-equipped fitness center. Featuring state-of-the-art cardio and strength training equipment, it's your personal sanctuary for wellness.</h3>
        <ul class="list-reverse">
          <li class="dotted-list">Open 24/7 for guests.</li>
          <li class="dotted-list">Fitness equipment available on a first-come, first-served basis. Children under [age] must be accompanied by an adult.</li>
          <li class="dotted-list">Group fitness classes offered on special occasions</li>
        </ul>
      </div>
    </div>
  </div> -->

  <!-- <div class="vc">
    <div class="pool-lb" style="text-align: center;font-size: 30px;">Spa & Wellness</div>
    <div class="pool-container">
      <div class="pool-text">
        <h3 style="padding-bottom: 20px;font-size: 20px;">Indulge in pure bliss at our spa and wellness center. Unwind with a variety of rejuvenating treatments, from soothing massages to invigorating facials.</h3>
        <ul class="points">
          <li class="dotted-list">Open daily from 6:00 AM to 10:00 PM.</li>
          <li class="dotted-list">Advance reservations required for spa treatments.</li>
          <li class="dotted-list">Spa facilities (sauna, steam room) may have specific time restrictions.</li>
        </ul>
      </div>
      <div class="pool-image">
        <img src="assets/images/wellness spa.png" alt="Spa & Wellness">
      </div>
    </div>
  </div> -->

  <!-- <div class="vc section-alternate reverse">
    <div class="pool-lb" style="text-align: center;font-size: 30px;">Restaurant</div>
    <div class="pool-container-reverse">
      <div class="pool-image">
        <img src="assets/images/restaurant.png" alt="Restaurant">
      </div>
      <div class="pool-text">
        <h3 style="padding-bottom: 20px;font-size: 20px;">Savor culinary delights at our on-site restaurant. Our talented chefs create exquisite dishes using fresh, local ingredients. Whether you're craving international cuisine or local flavors, we have something to satisfy every palate.</h3>
        <ul class="list-reverse">
          <li class="dotted-list">Breakfast served from 7:00 AM to 9:00 AM.</li>
          <li class="dotted-list">Lunch served from 12:00 PM to 3:00 PM.</li>
          <li class="dotted-list">Dinner served from 9:00 PM to 11:00 PM.</li>
          <li class="dotted-list">Reservations recommended for dinner.</li>
        </ul>
      </div>
    </div>
  </div> -->

  <!-- <div class="vc">
    <div class="pool-lb" style="text-align: center;font-size: 30px;">Bar & Lounge</div>
    <div class="pool-container">
      <div class="pool-text">
        <h3 style="padding-bottom: 20px;font-size: 20px;">Unwind and socialize at our stylish bar and lounge. Enjoy handcrafted cocktails, fine wines, and delicious appetizers while taking in the ambiance.</h3>
        <ul class="points">
          <li class="dotted-list">Open from 8:00 PM to 12:00 AM </li>
          <li class="dotted-list">Happy hour specials available from 9:00 PM to 11:00 PM.</li>
          <li class="dotted-list">Live music on Sunday.</li>
        </ul>
      </div>
      <div class="pool-image">
        <img src="assets/images/bar and lounge.png" alt="Bar & Lounge">
      </div>
    </div>
  </div> -->

  <!-- <div class="vc section-alternate reverse">
    <div class="pool-lb" style="text-align: center;font-size: 30px;">Banquet Hall</div>
    <div class="pool-container-reverse">
      <div class="pool-image">
        <img src="assets/images/banquet hall.png" alt="Banquet Hall">
      </div>
      <div class="pool-text">
        <h3 style="padding-bottom: 20px;font-size: 20px;">Host your special events in our elegant banquet hall. Perfect for weddings, conferences, or corporate gatherings, our versatile space can accommodate any occasion.</h3>
        <ul class="list-reverse">
          <li class="dotted-list">Available for bookings and private events.</li>
          <li class="dotted-list">Capacity of 1000 guests.</li>
          <li class="dotted-list">Inquire about catering options and event packages.</li>
        </ul>
      </div>
    </div>
  </div> -->
</div>